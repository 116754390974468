import {
    Area,
    AreaChart,
    Brush,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import React, {useCallback, useEffect, useState} from "react";
import {
    buildDataAggregated,
    buildSeries,
    findColorByKey,
    getFormattedNumber,
    getFormattedValue,
    getLabelByAggregation,
    getLineVisibility,
    noDataAvailableStyle,
    sendNotification
} from "../commons";
import moment from "moment";
import {FaImage, MdRestore} from "react-icons/all";
import {useCurrentPng} from "recharts-to-png";
import FileSaver from "file-saver";

function AreaChartComponent({title, data, brushKey, xAxisKey, yAxisUnit="€/MWh",
    aggregation, stackMode, type= "step",
    lineVisibilityDefault={}}) {

    const [lineVisibility, setLineVisibility] = useState(lineVisibilityDefault);
    const [getPng, { ref }] = useCurrentPng();

    const backup = console.warn;

    console.warn = function filterWarnings(msg) {
        const supressedWarnings = ['The width(0) and height(0) of chart should be greater than 0'];

        if (!supressedWarnings.some(entry => msg.includes(entry))) {
            backup.apply(console, arguments);
        }
    };

    useEffect(()=>{
    }, [data]);


    let keys = [];
    if(data.length > 0){
        if(data.length > 0 && data.length > 0) {
            keys = Object.keys(data[0]).filter(k => k !== 'time').map(k => k);
        }
    }

    function updateVisibility(event) {
        const lineStatus = event["dataKey"].trim() in lineVisibility ? lineVisibility[event["dataKey"].trim()] : null;
        const visibility = (lineStatus === "visible" || lineStatus == null) ? "hidden" : "visible";
        setLineVisibility({...lineVisibility, [event["dataKey"].trim()]: visibility});
        sendNotification(event["dataKey"].trim() + " is " + visibility, "success");
    }

    const renderColorfulLegendText = (val, entry) => {
        return getLineVisibility(val, entry);
    };

    let brush = null;
    if(brushKey){
        brush = <Brush tickFormatter={(val) => {
                    return moment.unix(val).format('DD-MM-YY');
                }} dataKey={brushKey} />
    }

    let xAxis;
    if(xAxisKey === "index") {
        const rangeFrom = data.length > 0 ? 100 / data.length : 0;
        xAxis = <XAxis dataKey={xAxisKey} angle="15" dy={10} height={45}
                       tickFormatter={(value) => value.toFixed(0)}
                       type="number" domain={[rangeFrom, 100]} unit={"%"}/>;
    }
    else{
        xAxis = <XAxis dataKey={xAxisKey}
                       tickFormatter={(item) => getLabelByAggregation(item, aggregation)}
                       angle="15" dy={10} height={45} />;
    }

    function getLabelText(val) {
        if(xAxisKey === "index") {
            return (val).toFixed(1) + " %";
        }
        else{
            return getLabelByAggregation(val, aggregation);
        }
    }

    let series = [];
    let aggData = [];
    if(xAxisKey === "index" && data.length > 0) {
        buildSeries(data, series);
        buildDataAggregated(data, series, aggData);
    }

    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            FileSaver.saveAs(png, title + '.png');
        }
    }, [getPng, title]);


    const iconsStyle = {
        marginLeft:"15px",
        cursor: "pointer"
    };


    return (
        <div style={{
            width: "100%",
            height: "450px"
        }}>
            <h6>
                {title}
                <MdRestore style={iconsStyle} onClick={()=>setLineVisibility({})} title="Restore to initial state"/>
                <FaImage style={iconsStyle} onClick={()=>handleDownload()} title="Download chart image"/>
            </h6>
            <ResponsiveContainer>
                {data.length > 0 ? (
                    <AreaChart data={xAxisKey === "index" ? aggData : data} ref={ref}
                               stackOffset="sign"
                               margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        {xAxis}
                        <YAxis unit={yAxisUnit}
                               tickFormatter={(value) => {
                                   return getFormattedNumber(value);
                               }} />
                        <Tooltip labelFormatter={(val) => getLabelText(val)}
                                 formatter={(value, name, props) => {
                            return [getFormattedValue(props, value), name];
                        }} animationDuration={0}/>
                        <Legend onClick={(event) => {
                                updateVisibility(event);
                            }}
                            wrapperStyle={{position: 'relative', marginTop: '0px'}}
                            formatter={renderColorfulLegendText}
                        />
                        {keys.map((f, idx) => {
                            return <Area key={f}
                                         visibility={f in lineVisibility ? lineVisibility[f] : "visible"}
                                         type={type}
                                         dataKey={(f in lineVisibility && lineVisibility[f] === "visible") || !(f in lineVisibility) ? f : (f + " ")}
                                         stroke={"none"}
                                         stackId={stackMode ? f : "1"}
                                         fill={findColorByKey(f, idx)}
                                         width="100%"
                                         height="100%"
                                         unit={yAxisUnit}
                                         connectNulls={stackMode}
                                         name={f}/>
                        })}
                        {brush}
                    </AreaChart>
                ) : (
                        <h5 style={noDataAvailableStyle}>No data available</h5>
                    )
                }
            </ResponsiveContainer>
        </div>
    )
}
export default AreaChartComponent;
