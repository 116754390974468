import React, {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {sendNotification} from "../commons";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import SimulationProgressMessages from "../simulations/SimulationProgressMessages";
import InvestmentFilters from "./InvestmentFilters";
import RegulatorSummary from "./views/RegulatorSummary";
import {FaEdit} from "react-icons/all";
import RenameSimulation from "../simulations/RenameSimulation";
import {useHistory} from "react-router-dom";
import AnalysisParametersForm from "./AnalysisParametersForm";
import RegulatorPlantsView from "./views/RegulatorPlantsView";
import InvestmentConfigForm from "./InvestmentConfigForm";
import InvestorCashFlowView from "./views/InvestorCashFlowView";
import InvestorBalanceView from "./views/InvestorBalanceView";
import RegulatorHydrogenSummary from "./views/RegulatorHydrogenSummary";
import SensitivityAnalysisView from "./views/SensitivityAnalysisView";

function InvestmentResults({
                               simulation,
                               simulationList,
                               dataSeries,
                               marketStructureList,
                               userId,
                               loadSimulation,
                               deleteSimulationModal,
                               updateSimulationList,
                               updateSimulation,
                               username
                           }) {

    let history = useHistory();

    const startSim = Date.parse(simulation["configuration"]["startEndSimulation"]["start"]);
    const endSim = Date.parse(simulation["configuration"]["startEndSimulation"]["end"]);

    const [selectedView, setSelectedView] = useState(simulation.configuration.projectType === 'Hydrogen' ? "hydrogenSummary" : "summary");
    const [selectedAggregation, setSelectedAggregation] = useState("Month");
    const [simulationsToCompare, setSimulationsToCompare] = useState([]);
    const [dataSeriesToCompare, setDataSeriesToCompare] = useState([]);
    const [simulationInitialValues, setSimulationInitialValues] = useState('');
    const [showConfig, setShowConfig] = useState(false);
    const [showAddParameters, setShowAddParameters] = useState(false);
    const [saveAsName, setSaveAsName] = useState("");
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [simulationParametersList, setSimulationParametersList] = useState([]);

    useEffect(()=>{
        setSimulationsToCompare([]);
    },[simulation.id, endSim, startSim]);

    useEffect(()=>{
    },[simulationsToCompare, selectedAggregation,
            selectedView,
            dataSeriesToCompare, simulationInitialValues]);

    useEffect(()=>{
        setSelectedView(['Hydrogen', 'Hydrogrid'].includes(simulation.configuration.projectType) ? "hydrogenSummary" : "summary");
    },[simulation]);


    useEffect(()=>{
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulation.id + '/parameters'))
            .then(res => res.json())
            .then(data => {
                setSimulationParametersList(data.list);
                if(data.list.length > 0){
                    setSimulationInitialValues(data.list[0].id);
                }
            });
    },[simulation.id]);

    const updateSimulationsComparison = (simulationsToCompare) =>{
        setSimulationsToCompare(simulationsToCompare);
    };

    const updateCompareDataSeries = (dataSeriesToCompare) =>{
        setDataSeriesToCompare(dataSeriesToCompare);
    };

    const updateSelectedSimulationInitialValues = (simulationParametersSettingsId) => {
        setSimulationInitialValues(simulationParametersSettingsId);
    }

    const cloneSimulation = () => {
        setShowSaveAs(false);
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulation.id + '/clone/' + userId, {
                method: 'POST' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: saveAsName
                })
            }))
            .then(res => res.json()).then(data => {
            if("error" in data){
                sendNotification(data.error, "danger");
            }else {
                updateSimulationList(data.list);
                loadSimulation(data.simulation.id);
                sendNotification('Simulation cloned', "success");
            }
        });
    }

    const archiverSimulation = () => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulation.id + '/archive', {
                method: 'PUT' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    archive: simulation.archive === 0 ? 1 : 0,
                    type: "energeia-investment"
                })
            }))
            .then(res => res.json()).then(data => {
            if("error" in data){
                sendNotification(data.error, "danger");
            }else {
                updateSimulationList(data.list);
                updateSimulation(null);
                sendNotification('Simulation archived', "success");
                history.push('/investments');
            }
        });
    }

    function getProjectTypeDescription(type){
        if(type === 'Renewables'){
            return type;
        }else if(type==="Hydrogen"){
            return "Hydrogen (island mode)";
        }else if(type==="Hydrogrid"){
            return "Hydrogen (grid connected)";
        }
        return "Not valid type"
    }

    const simulationDate = (<Col sm={3}>
        <Row>
            <Col sm={12}>
                <b>Simulation Date: </b>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                {simulation.simulation_date.join(" ")}
            </Col>
        </Row>
    </Col>);

    const simulationLegend =
        <Alert variant="primary" style={{marginTop: "10px"}}>
            <Row>
                <Col sm={8}>
                    <Row>
                        <Col sm={4}>
                            <Row>
                                <Col sm={12}>
                                    <b>Market Structure:</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {simulation.market_structure.filename}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3}>
                            <Row>
                                <Col sm={12}>
                                    <b>Project Type: </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {getProjectTypeDescription(simulation.configuration.projectType)}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2}>
                            <Row>
                                <Col sm={12}>
                                    <b>Name: </b>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {simulation.name}
                                    <button type={"button"} className="btn btn-sm btn-outline-secondary btn-secondary"
                                            style={{marginLeft:"5px", backgroundColor:"white"}}
                                            onClick={()=>setShowRename(!showRename)}>
                                        <FaEdit/>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                        {simulationDate}
                    </Row>
                </Col>
                <Col sm={4}>
                    <Row>
                        <Col sm={12}>
                            <button type="button" className="btn btn-sm btn-outline-primary float-left"
                                    style={{marginLeft:"5px"}}  id={simulation.id}
                                    onClick={() => setShowConfig(!showConfig)}
                            >
                                Configuration
                            </button>
                            <button type="button" className="btn btn-sm btn-outline-primary float-left"
                                    style={{marginLeft:"5px"}}  id={simulation.id}
                                    onClick={() => setShowLogs(!showLogs)}
                            >
                                Progress
                            </button>
                            <button
                                style={{marginLeft:"5px"}} onClick={() => {
                                setSaveAsName(simulation.name + "Clone");
                                setShowSaveAs(true);
                            }}
                                className="btn btn-sm btn-outline-primary float-left">
                                Save as
                            </button>
                            <button
                                style={{marginLeft:"5px"}} onClick={() => {
                                archiverSimulation()
                            }}
                                className="btn btn-sm btn-outline-primary float-left">
                                {simulation.archive === 1 ? 'Unarchive' : 'Archive'}
                            </button>
                            <button style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-danger float-right"
                                    id={simulation.id}
                                    onClick={deleteSimulationModal}>
                                Delete
                            </button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col sm={12}>
                            <button style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-primary float-left"
                                    id={simulation.id}
                                    onClick={() => setShowAddParameters(!showAddParameters)}>
                                Add Valuation Parameters
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Alert>;

    let gridResults = null;

    if(simulation.parameters.length === 0){
        gridResults = <h1>Please choose the valuation parameters</h1>
    }
    else if(selectedView){
        switch (selectedView) {
            case "summary":  gridResults = <RegulatorSummary viewName={"Regulator's view -" +
                " Summary"}
                                                             simulationInitialValues={simulationInitialValues}
                                                             typeName={"general"}
                                                             aggregation={selectedAggregation}
                                                             simulationId={simulation.id}
                                                             simulation={simulation}
            />;
                             break;
            case "hydrogenSummary":  gridResults = <RegulatorHydrogenSummary viewName={"Regulator's view" +
                " -" +
                " Summary"}
                                                             simulationInitialValues={simulationInitialValues}
                                                             typeName={"general"}
                                                             aggregation={selectedAggregation}
                                                             simulationId={simulation.id}
                                                             simulation={simulation}
            />;
                break;
            case "productionByPlant":  gridResults = <RegulatorPlantsView viewName={"Monthly" +
                " production by plant (MWh)"}
                                                                       simulationInitialValues={simulationInitialValues}
                                                                       typeName={"production"}
                                                                       aggregation={selectedAggregation}
                                                                       simulationId={simulation.id}
                                                                       simulation={simulation}
                                                                       yAxisUnit="MWh"
            />;
                break;
            case "electricityProduction":  gridResults = <RegulatorPlantsView viewName={"Electricity Production (MWh)"}
                                                                          simulationInitialValues={simulationInitialValues}
                                                                          typeName={"production"}
                                                                          aggregation={selectedAggregation}
                                                                          simulationId={simulation.id}
                                                                          simulation={simulation}
                                                                          yAxisUnit="MWh"
            />;
                break;
            case "captureGridPrice":  gridResults = <RegulatorPlantsView viewName={"Captured Grid" +
                " Price"}
                                                                              simulationInitialValues={simulationInitialValues}
                                                                              typeName={"captureGridPrice"}
                                                                              aggregation={selectedAggregation}
                                                                              simulationId={simulation.id}
                                                                              simulation={simulation}
            />;
                break;
            case "productionByDestination":  gridResults = <RegulatorPlantsView viewName={"Production by Use"}
                                                                         simulationInitialValues={simulationInitialValues}
                                                                         typeName={"production_by_destination"}
                                                                         aggregation={selectedAggregation}
                                                                         simulationId={simulation.id}
                                                                         yAxisUnit={"MWh"}
                                                                         simulation={simulation}
            />;
                break;
            case "productionByUsePercentage":  gridResults = <RegulatorPlantsView viewName={"Share of Production by Use (Percent)"}
                                                                                simulationInitialValues={simulationInitialValues}
                                                                                typeName={"production_by_destination_percentage"}
                                                                                aggregation={selectedAggregation}
                                                                                simulationId={simulation.id}
                                                                                yAxisUnit={"%"}
                                                                                simulation={simulation}
            />;
                break;
            case "hydrogenProduction":  gridResults = <RegulatorPlantsView viewName={"Hydrogen" +
                " Production (Tons)"}
                                                                              simulationInitialValues={simulationInitialValues}
                                                                              typeName={"h2_production"}
                                                                              aggregation={selectedAggregation}
                                                                              simulationId={simulation.id}
                                                                              simulation={simulation}
                                                                              yAxisUnit={"Tons"}
            />;
                break;
            case "totalCostByPlant":  gridResults = <RegulatorPlantsView viewName={"Average" +
                " price by plant (€/MWh)"}
                                                                 simulationInitialValues={simulationInitialValues}
                                                                 typeName={"total_cost"}
                                                                 aggregation={selectedAggregation}
                                                                 simulationId={simulation.id}
                                                                 simulation={simulation}
                                                                 yAxisUnit="€/MWh"
                                            />;
                break;
            case "avgMarketPrice":  gridResults = <RegulatorPlantsView viewName={"Captured price" +
                " by plant (€/MWh)"}
                                                                            simulationInitialValues={simulationInitialValues}
                                                                            typeName={"profits_per_MWh"}
                                                                            aggregation={selectedAggregation}
                                                                            simulationId={simulation.id}
                                                                            simulation={simulation}
                                                                            yAxisUnit="€/MWh"
                                                                            />;
                break;
            case "averageRegulatedIncome":  gridResults = <RegulatorPlantsView viewName={"Average regulated income by plant (€/MWh)"}
                                                                               simulationInitialValues={simulationInitialValues}
                                                                               typeName={"market_profits_per_MWh"}
                                                                               aggregation={selectedAggregation}
                                                                               simulationId={simulation.id}
                                                                               simulation={simulation}
                                                                               yAxisUnit="€/MWh"
            />;
                break;
            case "averageCostPerMWh":  gridResults = <RegulatorPlantsView viewName={"Average cost by plant (€/MWh)"}
                                                                          simulationInitialValues={simulationInitialValues}
                                                                          typeName={"reg_revenue_per_MWh"}
                                                                          aggregation={selectedAggregation}
                                                                          simulationId={simulation.id}
                                                                          simulation={simulation}
                                                                          yAxisUnit="€/MWh"
            />;
                break;
            case "investorSummary":  gridResults = <RegulatorSummary viewName={"Investor's view" +
                " - Summary"}
                                                                     simulationInitialValues={simulationInitialValues}
                                                                     typeName={"general"}
                                                                     aggregation={selectedAggregation}
                                                                     simulationId={simulation.id}
                                                                     simulation={simulation}
                                                                     isHydro={false}
            />;
                break;
            case "investorSummaryHydro":  gridResults = <RegulatorSummary viewName={"Investor's" +
                " view - Summary"}
                                                                     simulationInitialValues={simulationInitialValues}
                                                                     typeName={"general"}
                                                                     aggregation={selectedAggregation}
                                                                     simulationId={simulation.id}
                                                                     simulation={simulation}
                                                                     isHydro={true}
            />;
                break;
            case "cashFlows":  gridResults = <InvestorCashFlowView viewName={"Investor's cash" +
                " flows "}
                                                                  simulationInitialValues={simulationInitialValues}
                                                                  typeName={"cash_flow"}
                                                                  aggregation={selectedAggregation}
                                                                  simulationId={simulation.id}
                                                                  simulation={simulation}
            />;
                break;
            case "balanceSheet":  gridResults = <InvestorBalanceView viewName={"Investor's" +
                " Balance Sheet"}
                                                                      simulationInitialValues={simulationInitialValues}
                                                                      typeName={"balance"}
                                                                      aggregation={selectedAggregation}
                                                                      simulationId={simulation.id}
                                                                      simulation={simulation}
            />;
                break;
            case "sensitivityAnalysis":  gridResults = <SensitivityAnalysisView viewName={"Sensitivity Analysis"}
                                                                            simulationInitialValues={simulationInitialValues}
                                                                            typeName={"sensitivity_analysis"}
                                                                            aggregation={selectedAggregation}
                                                                            simulationId={simulation.id}
                                                                            simulation={simulation}
            />;
                break;

            default: gridResults = <h4>Option not found</h4>
                break;
        }
    }else{
        gridResults = <h4>Loading Results...</h4>
    }



    const handleClose = () => setShowConfig(false);

    return (
        <div>
            <Row>
                <Col sm={12}>
                    {simulationLegend}
                </Col>
            </Row>
            <Row className="simulation-chart">
                <Col sm={2}>
                    <InvestmentFilters defaultView={selectedView}
                                       simulation={simulation}
                                       simulationList={simulationList}
                                       updateView={setSelectedView}
                                       updateCompareSimulations={updateSimulationsComparison}
                                       updateCompareDataSeries={updateCompareDataSeries}
                                       simulationsToCompare={simulationsToCompare}
                                       dataSeriesToCompare={dataSeriesToCompare}
                                       updateSelectedSimulationInitialValues={updateSelectedSimulationInitialValues}
                                       simulationInitialValues={simulationInitialValues}
                                       updateAggregation={setSelectedAggregation}
                                       simulationParametersList={simulationParametersList}
                                       userId={userId}
                    />
                </Col>
                <Col sm={10}>
                    {gridResults}
                </Col>
            </Row>
            {
                showSaveAs &&
                <Row>
                    <Modal show={showSaveAs} onHide={()=>{
                        setShowSaveAs(false)
                    }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Save as</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Label>Name
                                <Form.Control type="text" placeholder="Investment Name" isInvalid={saveAsName === ''} size="sm" value={saveAsName} onChange={event=>{
                                    setSaveAsName(event.target.value);
                                }}/>
                            </Form.Label>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{
                                setShowSaveAs(false)
                            }}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={cloneSimulation}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
            {showConfig &&
                <Row>
                    <Modal show={showConfig} onHide={handleClose} animation={false} size={"xl"}>
                        <Modal.Header closeButton>
                            <Modal.Title>Configuration</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <fieldset disabled="disabled">
                                <InvestmentConfigForm investment={simulation}
                                                      dataSeries={dataSeries}
                                                      marketStructureList={marketStructureList}
                                                      userId={userId}
                                                      readOnly={true}
                                />
                            </fieldset>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
            {showAddParameters &&
                <Row>
                    <AnalysisParametersForm simulation={simulation}
                                            updateSimulation={updateSimulation}
                                            showAddParameters={showAddParameters}
                                            setSimulationInitialValues={updateSelectedSimulationInitialValues}
                                            setShowAddParameters={setShowAddParameters}
                                            setSimulationParametersList={setSimulationParametersList}/>
                </Row>
            }
            {showRename &&
                <RenameSimulation show={showRename}
                                  simulation={simulation}
                                  updateSimulationList={updateSimulationList}
                                  updateSimulation={updateSimulation}
                                  userId={userId}
                                  type={"energeia-investment"}
                />
            }
            {
                showLogs &&
                <Row>
                    <Modal show={showLogs} size={"lg"} onHide={()=>{
                        setShowLogs(false)
                    }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Simulation progress</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SimulationProgressMessages simulationId={simulation.id}
                                                        userId={username}
                                                        isStrategy={simulation.configuration.strategy} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{
                                setShowLogs(false)
                            }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
        </div>
    );
}

export default InvestmentResults;
