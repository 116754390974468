/* eslint-disable */
import {
    Brush,
    CartesianGrid,
    Label,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer, Scatter, ScatterChart,
    Tooltip,
    XAxis,
    YAxis, ZAxis
} from "recharts";
import React, {useCallback, useEffect, useState} from "react";
import {
    buildDataAggregated,
    buildSeries,
    findColorByKey,
    generateRandomColor,
    getFormattedValue,
    getLabelByAggregation,
    getLineVisibility,
    noDataAvailableStyle,
    sendNotification
} from "../commons";
import moment from "moment";
import {FaImage, MdRestore} from "react-icons/all";
import {useCurrentPng} from "recharts-to-png";
import FileSaver from "file-saver";

function getMaxLength(data) {
    let max = 0;
    if(data && data.length > 0){
        data.forEach(d=>{
            if(d.length > max){
                max = d.length;
            }
        });
    }
    return max;
}

function ScatterChartComponent({title, data, brushKey, xAxisKey,
    yAxisName, groupBy, xAxisName}) {
    const backup = console.warn;

    console.warn = function filterWarnings(msg) {
        const supressedWarnings = ['The width(0) and height(0) of chart should be greater than 0'];

        if (!supressedWarnings.some(entry => msg.includes(entry))) {
            backup.apply(console, arguments);
        }
    };


    const [lineVisibility, setLineVisibility] = useState({});
    const [getPng, { ref }] = useCurrentPng();

    useEffect(()=>{
    }, [data]);

    let keys = [];
    if(data.length > 0){
        keys = data.map(d=>d[0]["name"])
    }

    function updateVisibility(event) {
        const lineStatus = event["dataKey"].trim() in lineVisibility ? lineVisibility[event["dataKey"].trim()] : null;
        const visibility = (lineStatus === "visible" || lineStatus == null) ? "hidden" : "visible";
        setLineVisibility({...lineVisibility, [event["dataKey"].trim()]: visibility});
        sendNotification(event["dataKey"].trim() + " is " + visibility, "success");
    }

    let brush = null;
    if(brushKey){
        brush = <Brush tickFormatter={(val) => {
                    return moment.unix(val).format('DD-MM-YY');
                }} dataKey={brushKey} />
    }

    let filteredData = [];
    filteredData = data && data.length > 0 && data.map(d=>{
        return d.filter(dm=>dm["z"] > 0);
    });

    let xAxis;
    if(xAxisKey === "index") {
        xAxis = <XAxis dataKey="x"
                       type={"number"}
                       hide={true}
                       range={[0, getMaxLength(filteredData)]}
                       angle="15"
                       height={45}
                       name={xAxisName}
        />;
    }
    else{
        xAxis = <XAxis dataKey={xAxisKey} tickFormatter={(item) => moment.unix(item).format('DD-MM-YY HH:mm')} angle="15" dy={10} height={45} />;
    }

    let series = [];
    let aggData = [];
    if(xAxisKey === "index" && filteredData && filteredData.length > 0) {
        buildSeries(filteredData, series);
        buildDataAggregated(filteredData, series, aggData);
    }


    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
          // Download with FileSaver
            FileSaver.saveAs(png, title + '.png');
        }
    }, [getPng, title]);


    const iconsStyle = {
        marginLeft:"15px",
        cursor: "pointer"
    };

    const renderColorfulLegendText = (val, entry) => {
        return getLineVisibility(val, entry);
    };

    function buildMessage(props, name){
        if(name === xAxisName){
            if(groupBy === 'Hour') {
                return moment.unix(props["payload"]["time"]).format(
                    'DD-MM-YYYY HH:mm')
            }else {
                return props["payload"]["entryDate"]
            }
        }else if(name === yAxisName){
            return props["payload"]["name"];
        }else{
            if(groupBy === 'Hour') {
                return "1";
            }else{
                return parseInt(props["payload"]["z"]) + "%";
            }
        }
    }

    return (
        <div style={{
            width: "100%",
            height: "450px"
        }}>
            <h6>
                {title}
                <MdRestore style={iconsStyle} onClick={()=>setLineVisibility({})} title="Restore to initial state"/>
                <FaImage style={iconsStyle} onClick={()=>handleDownload()} title="Download chart image"/>
            </h6>
            <ResponsiveContainer width='99%'>
                {data.length > 0 ?
                    (
                        <ScatterChart margin={{ top: 20, right: 20, bottom: 10, left: 10 }} ref={ref}>
                            <CartesianGrid strokeDasharray="3 3" />
                            {xAxis}
                            <YAxis dataKey="y" type={"number"} hide={true} name={yAxisName}/>
                            <ZAxis dataKey="z" range={[0, 100]} name="Price Setter" />
                            <Tooltip formatter={(value, name, props) => {
                                         return [buildMessage(props, name), name];
                                     }} animationDuration={0}/>
                            <Legend onClick={(event) => {
                                        updateVisibility(event);
                                    }}
                                    formatter={renderColorfulLegendText}
                            />
                            {
                                keys.map((f, idx)=>{
                                    return <Scatter
                                                    key={idx}
                                                    visibility={f in lineVisibility ? lineVisibility[f] : "visible"}
                                                    dataKey={(f in lineVisibility && lineVisibility[f] === "visible") || !(f in lineVisibility) ? f : (f + " ")}
                                                    name={filteredData[idx]["name"]}
                                                    data={(f in lineVisibility && lineVisibility[f] === "visible") || !(f in lineVisibility) ? filteredData[idx] : []}
                                                    fill={findColorByKey(f, idx)}/>
                                })
                            }
                            {brush}
                        </ScatterChart>
                    ) : (
                        <h5 style={noDataAvailableStyle}>No data available</h5>
                    )
                }

            </ResponsiveContainer>
        </div>
    )
}
export default ScatterChartComponent;
