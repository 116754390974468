import {Alert, Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import icon_simulations from "../../img/Icon Simulations.png"

import {useHistory, useParams} from "react-router-dom";
import Investment from "./Investment";
import {trackPromise} from "react-promise-tracker";
import SimulationInfo from "../simulations/SimulationInfo";
import socket from "../common/sockets";
import {sendNotification} from "../commons";
import bg from "../../img/Background sections-01.png";


function Investments({ userId, username }) {
    let history = useHistory();
    const [marketStructureList, setMarketStructureList] = useState([]);
    const [dataSeries, setDataSeries] = useState([]);
    const [investment, setInvestment] = useState(null);
    const [investments, setInvestments] = useState([]);
    const [socketOn, setSocketOn] = useState(false);
    const [simulationToDelete, setSimulationToDelete] = useState(null);
    const [showDeleteSimulation, setShowDeleteSimulation] = useState(false);
    const [showArchivedSimulations, setShowArchivedSimulations] = useState(false);
    let { simId } = useParams();

    useEffect(()=>{
        if(!socketOn && username) {
            setSocketOn(true);
            socket.on(username, function (data) {
                if (data.event === "run_simulation" && data.message === 'Simulation Done') {
                    sendNotification(data.step, "success");
                    loadInvestment(data.simId);
                    trackPromise(
                        fetch('/m40alasocho/user/' + userId + '/simulations/min?type=energeia-investment'))
                        .then(res => res.json()).then(data => {
                        setInvestments(data.list);
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, [username, investments]);




    useEffect(() => {
        if (simId != null) {
            trackPromise(
                fetch('/m40alasocho/simulation/' +  simId))
                .then(res => res.json()).then(data => {
                setInvestment(data.simulation);
                sendNotification('Simulation ' + data.simulation.name + ' Loaded', "success");
            });
        }
    }, [simId]);

    const dataSeriesWarning = dataSeries.filter(ds => ds.type !== 'price' && ds.type !== 'technology_generation')
        .filter(ds =>ds["files"].length === 0).length > 0;

    useEffect(() => {
        if (userId) {
            trackPromise(
                fetch('/m40alasocho/market/structure/user/' + userId))
                .then(res => res.json()).then(data => {
                setMarketStructureList(data.list.map(i => {
                    return {
                        value: i.id,
                        label: i.filename + " " + i.import_date.join(" ")
                    }
                }));
            });

            trackPromise(
                fetch('/m40alasocho/user/' + userId + '/simulations/min?type=energeia-investment'))
                .then(res => res.json()).then(data => {
                setInvestments(data.list);
            });

            trackPromise(
                fetch('/m40alasocho/data/series/user/' + userId))
                .then(res => res.json()).then(data => {
                setDataSeries(data.list);
            });
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            trackPromise(
                fetch('/m40alasocho/user/' + userId + '/simulations/min?type=energeia-investment&archived='+showArchivedSimulations))
                .then(res => res.json()).then(data => {
                setInvestments(data.list);
            });
        }
    }, [userId, showArchivedSimulations]);

    const updateInvestmentList = (list) => {
        setInvestments([...list]);
    };

    const cancelSimulationChanges = () => {
        setInvestment(null);
        history.push('/investments');
    };

    const loadInvestment = (simulationId) => {
        history.push('/investments/' + simulationId);
    };

    const showAddInvestment = useCallback(
        event => {
            event.preventDefault();
            history.push('/investments');
            setInvestment({
                id: "",
                status: "NEW"
            });
        },
        [history]
    );

    const deleteSimulationModal = useCallback(
        event => {
            event.preventDefault();
            setSimulationToDelete(event.target.id);
            setShowDeleteSimulation(true);
        },
        []
    );

    const deleteSimulation = () => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationToDelete, { method: 'DELETE' }))
            .then(res => res.json()).then(data => {
            history.push('/investments');
            simId = null;
            setSimulationToDelete(null);
            handleCloseDeleteSimulation();
            setInvestments(data.list);
            setInvestment(null);
            sendNotification('Simulation deleted', "success");
        });
    };

    const handleCloseDeleteSimulation = () => setShowDeleteSimulation(false);

    const investmentData = investment ? <Investment investment={investment}
                                                    dataSeries={dataSeries}
                                                    userId={userId}
                                                    username={username}
                                                    deleteSimulationModal={deleteSimulationModal}
                                                    marketStructureList={marketStructureList}
                                                    updateSimulationList={updateInvestmentList}
                                                    loadInvestment={loadInvestment}
                                                    cancelSimulationChanges={cancelSimulationChanges}
                                                    updateInvestment={setInvestment}
                                                    updateInvestmentList={setInvestments}
                                                    simulationList={investments}
        />
        :
        (<Container className='filler'>
            <Row>
                <Col md={{ span: 9, offset: 1 }}>
                    <h1>
                        INVESTMENTS
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 9, offset: 1 }}>
                    <p>
                        The investments section allows you to model the market impact of low-carbon investments as well as to perform a financial assessment of those investments.
                    </p>
                    {marketStructureList.length === 0 &&
                        <p>
                            <span style={{"color": "red"}}>Please create at least one market structure before create a simulation</span>
                        </p>
                    }

                    {dataSeriesWarning &&
                        <p>
                            <span style={{"color": "red"}}>Please create at least one Data Series by each type</span>
                        </p>
                    }
                </Col>
            </Row>
        </Container>);

    useEffect(()=>{
        investment ?
            document.getElementsByClassName("modal-right col-sm-10")[0].style.backgroundImage = 'none'
            :
            document.getElementsByClassName("modal-right col-sm-10")[0].style.backgroundImage = `url('${bg}')`
        document.getElementById('bodyId').style.backgroundImage = 'none';
    }, [investment]);

    function getSortedTags(simulations){
        let uniqueTags = [];
        simulations.forEach(s=>{
            if(!uniqueTags.includes(s.tag)){
                uniqueTags.push(s.tag);
            }
        });
        return uniqueTags.sort();
    }

    function getSimulationsByTag(tag, simulations){
        return simulations.filter(s=>s.tag === tag);
    }


    return (
        <Container fluid>
            <Row>
                <Col sm={2} className="text-left modal-left">
                    <div className="text-left" style={{ marginTop: "10px" }}>
                        <Alert variant="primary" className="button-primary-cover">
                            <Row>
                                <Col sm={3}>
                                    <img src={icon_simulations} alt="Investments" style={{ height: "40px" }}></img>
                                </Col>
                                <Col>
                                    <p>
                                        INVESTMENTS
                                    </p>
                                </Col>
                                <Col sm={3}>
                                    <button type="button" className="btn btn-sm btn-outline-primary float-right button-primary"
                                            style={{ marginRight: "5px" }} onClick={showAddInvestment}>ADD</button>
                                </Col>
                            </Row>
                        </Alert>
                        <div className={"fixed-list-simulations"}>
                            <Form.Check type="checkbox" label="Show Archived simulations" checked={showArchivedSimulations}
                                        style={{marginLeft: "10px"}}
                                        onChange={event=>{
                                            setShowArchivedSimulations(!showArchivedSimulations);
                                        }}/>
                            {
                                getSortedTags(investments).map((t, it)=>{
                                    return <fieldset key={it}>
                                        <legend>{t !== null ? t : "Without Category"}</legend>
                                        {
                                            getSimulationsByTag(t, investments).map((item, index) => {
                                                return (<SimulationInfo key={item.id} simulation={item} callbackLoadSimulation={loadInvestment} />);
                                            })}
                                    </fieldset>
                                })
                            }
                        </div>
                    </div>
                </Col>
                <Col sm={10} className="modal-right">
                    {investmentData}
                </Col>
            </Row>
            <Modal show={showDeleteSimulation} onHide={handleCloseDeleteSimulation} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Investment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this investment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteSimulation}>
                        Cancel
                    </Button>
                    <Button variant="danger" className="button-danger" onClick={deleteSimulation}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Investments;