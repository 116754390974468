import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {ErrorFallback} from "../commons";
import BarChartWrapper from "../common/BarChartWrapper";


/**
 * @return {null}
 */
function TechnologyCapacity({data}) {

    let companies = [];
    data.forEach(m => {
        if(!companies.includes(m.technology)){
            return companies.push(m.technology);
        }
    });
    let companiesInfo = {};
    let technologyData = [];
    companies.forEach(c=>{
        technologyData.push(data.filter(m=>m.technology === c).reduce((result, filter) => {
            result["name"] = c;
            result[filter.company_id] = filter.power;
            companiesInfo[filter.company_id] = filter.company;
            return result;
        },{}));
    });


    return data && data.length > 0 ? (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <BarChartWrapper title="Capacity by Technologies"
                                 yAxisUnit={"MW"}
                                 data={technologyData} bars={companiesInfo} xAxisKey="name"/>
            </div>
        </ErrorBoundary>
    ) : null;
}

export default TechnologyCapacity;
