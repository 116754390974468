import {Alert, Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {notify} from "react-notify-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import SimulationInfo from "./SimulationInfo";
import Simulation from "./Simulation";
import icon_simulations from "../../img/Icon Simulations.png"
import bg from "../../img/Background sections-01.png"

import {useHistory, useParams} from "react-router-dom";
import socket from "../common/sockets";


function Simulations({ userId, username }) {
    const [marketStructureList, setMarketStructureList] = useState([]);
    const [showDeleteSimulation, setShowDeleteSimulation] = useState(false);
    const [simulations, setSimulations] = useState([]);
    const [dataSeries, setDataSeries] = useState([]);
    const [simulation, setSimulation] = useState(null);
    const [simulationToDelete, setSimulationToDelete] = useState(null);
    const [socketOn, setSocketOn] = useState(false);
    const [showArchivedSimulations, setShowArchivedSimulations] = useState(false);
    let { simId } = useParams();

    let history = useHistory();

    useEffect(()=>{
        if(!socketOn && username) {
            setSocketOn(true);
            socket.on(username, function (data) {
                if (data.event === "run_simulation" && data.message === 'Simulation Done') {
                    sendNotification(data.step, "success");
                    loadSimulation(data.simId);
                    trackPromise(
                        fetch('/m40alasocho/user/' + userId + '/simulations/min'))
                        .then(res => res.json()).then(data => {
                        setSimulations(data.list);
                    });
                }
            });
        }
        // eslint-disable-next-line
    }, [username, simulations]);




    useEffect(() => {
        if (simId != null) {
            trackPromise(
                fetch('/m40alasocho/simulation/' +  simId))
                .then(res => res.json()).then(data => {
                    setSimulation(data.simulation);
                    sendNotification('Simulation ' + data.simulation.name + ' Loaded', "success");
                });
        }
    }, [simId]);


    useEffect(() => {
        trackPromise(
            fetch('/m40alasocho/market/structure/user/' + userId))
            .then(res => res.json()).then(data => {
                setMarketStructureList(data.list.map(i => {
                    return {
                        value: i.id,
                        label: i.filename + " " + i.import_date.join(" ")
                    }
                }));
            });
    }, [userId]);

    const deleteSimulationModal = useCallback(
        event => {
            event.preventDefault();
            setSimulationToDelete(event.target.id);
            setShowDeleteSimulation(true);
        },
        []
    );

    useEffect(() => {
        if (userId) {
            trackPromise(
                fetch('/m40alasocho/user/' + userId + '/simulations/min?type=energeia-simula&archived='+showArchivedSimulations))
                .then(res => res.json()).then(data => {
                    setSimulations(data.list);
                });
            trackPromise(
                fetch('/m40alasocho/data/series/user/' + userId))
                .then(res => res.json()).then(data => {
                    setDataSeries(data.list);
                });
        }
    }, [userId, showArchivedSimulations]);

    useEffect(() => {
        if (userId) {
            trackPromise(
                fetch('/m40alasocho/user/' + userId + '/simulations/min?type=energeia-simula&archived='+showArchivedSimulations))
                .then(res => res.json()).then(data => {
                setSimulations(data.list);
            });
        }
    }, [userId, showArchivedSimulations]);

    const sendNotification = (innerHTML, variant) => {
        notify({ text: innerHTML, variant: variant });
    };

    const showAddSimulation = useCallback(
        event => {
            event.preventDefault();
            history.push('/simulations');
            setSimulation({
                id: "",
                status: "NEW"
            });
        },
        [history]
    );

    const deleteSimulation = () => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulationToDelete, { method: 'DELETE' }))
            .then(res => res.json()).then(data => {
                history.push('/simulations');
                simId = null;
                setSimulationToDelete(null);
                handleCloseDeleteSimulation();
                setSimulations(data.list);
                setSimulation(null);
                sendNotification('Simulation deleted', "success");
            });
    };

    const loadSimulation = (simulationId) => {
        history.push('/simulations/' + simulationId);
    };


    const handleCloseDeleteSimulation = () => setShowDeleteSimulation(false);
    const cancelSimulationChanges = () => {
        setSimulation(null);
    };

    const updateSimulationList = (list) => {
        setSimulations([...list]);
    };

    const dataSeriesWarning = dataSeries.filter(ds => ds.type !== 'price' && ds.type !== 'technology_generation')
                                        .filter(ds =>ds["files"].length === 0).length > 0;

    const simulationData = simulation ? <Simulation simulation={simulation}
        dataSeries={dataSeries}
        userId={userId}
        username={username}
        deleteSimulationModal={deleteSimulationModal}
        marketStructureList={marketStructureList}
        updateSimulationList={updateSimulationList}
        loadSimulation={loadSimulation}
        cancelSimulationChanges={cancelSimulationChanges}
        updateSimulation={setSimulation}
        simulationList={simulations}
    />
        :
        (<Container className='filler'>
            <Row>
                <Col md={{ span: 9, offset: 1 }}>
                    <h1>
                        SIMULATIONS
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 9, offset: 1 }}>
                    <p>
                        The simulations section allows you to define the timeframe and assumptions for your simulations, run them, visualize the outcomes and compare them.
                        You can add new simulations or work with the outcomes of already created simulations.
                    </p>
                    {marketStructureList.length === 0 &&
                        <p>
                            <span style={{"color": "red"}}>Please create at least one market structure before create a simulation</span>
                        </p>
                    }

                    {dataSeriesWarning &&
                        <p>
                            <span style={{"color": "red"}}>Please create at least one Data Series by each type</span>
                        </p>
                    }
                </Col>
            </Row>
        </Container>);

    useEffect(()=>{
        simulation ?
            document.getElementsByClassName("modal-right col-sm-10")[0].style.backgroundImage = 'none'
            :
            document.getElementsByClassName("modal-right col-sm-10")[0].style.backgroundImage = `url('${bg}')`
        document.getElementById('bodyId').style.backgroundImage = 'none';
    }, [simulation]);


    function getSortedTags(simulations){
        let uniqueTags = [];
        let hasNulls = false;
        simulations.forEach(s=>{
            if(s.tag == null){
                hasNulls = true;
            }
            if(s.tag != null && !uniqueTags.includes(s.tag)){
                uniqueTags.push(s.tag);
            }
        });
        if(hasNulls) {
            uniqueTags.sort();
            uniqueTags.push(null);
            return uniqueTags;
        }else{
            return uniqueTags.sort();
        }
    }

    function getSimulationsByTag(tag, simulations){
        return simulations.filter(s=>s.tag === tag);
    }


    return (
        <Container fluid>
            <Row>
                <Col sm={2} className="text-left modal-left">
                    <div className="text-left" style={{ marginTop: "10px" }}>
                        <Alert variant="primary" className="button-primary-cover">
                            <Row>
                                <Col sm={3}>
                                    <img src={icon_simulations} alt="Simulations" style={{ height: "40px" }}></img>
                                </Col>
                                <Col>
                                    <p>
                                        SIMULATIONS
                                    </p>
                                </Col>
                                <Col sm={3}>
                                    <button type="button" className="btn btn-sm btn-outline-primary float-right button-primary"
                                            disabled={marketStructureList.length === 0}
                                            style={{ marginRight: "5px" }} onClick={showAddSimulation}>ADD</button>
                                </Col>
                            </Row>
                        </Alert>
                        <div className={"fixed-list-simulations"}>
                            <Form.Check type="checkbox" label="Show Archived simulations" checked={showArchivedSimulations}
                                        style={{marginLeft: "10px"}}
                                        onChange={event=>{
                                setShowArchivedSimulations(!showArchivedSimulations);
                            }}/>
                            {
                                getSortedTags(simulations).map((t, it)=>{
                                    return <fieldset key={it}>
                                                <legend>{t !== null ? t : "Without Category"}</legend>
                                                {
                                                    getSimulationsByTag(t, simulations).map((item, index) => {
                                                                                         return (<SimulationInfo key={item.id} simulation={item} callbackLoadSimulation={loadSimulation} />);
                                                })}
                                           </fieldset>
                                })
                            }
                        </div>
                    </div>
                </Col>
                <Col sm={10} className="modal-right">
                    {simulationData}
                </Col>
            </Row>
            <Modal show={showDeleteSimulation} onHide={handleCloseDeleteSimulation} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Simulation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this simulation?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteSimulation}>
                        Cancel
                    </Button>
                    <Button variant="danger" className="button-danger" onClick={deleteSimulation}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Simulations;