import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import PriceOutput from "./PriceOutput";
import SimulationFilters from "./SimulationFilters";
import GenerationOutput from "./GenerationOutput";
import RenewablesExcessOutput from "./RenewablesExcessOutput";
import MarginalCostsOutput from "./MarginalCostsOutput";
import TotalCostsOutput from "./TotalCostsOutput";
import ProfitOutput from "./ProfitOutput";
import EmissionOutput from "./EmissionOutput";
import MarkupOutput from "./MarkupOutput";
import PriceSetterOutput from "./PriceSetterOutput";
import SimulationConfigForm from "./SimulationConfigForm";
import ConsumerPaymentOutput from "./ConsumerPaymentOutput";
import {trackPromise} from "react-promise-tracker";
import {getDomainByLocation, sendNotification} from "../commons";
import SimulationProgressMessages from "./SimulationProgressMessages";
import CapturedPriceOutput from "./CapturedPriceOutput";
import {FaEdit} from "react-icons/all";
import RenameSimulation from "./RenameSimulation";
import {useHistory} from "react-router-dom";

function SimulationResults({simulation,
                               simulationList,
                               dataSeries,
                               marketStructureList,
                               userId,
                               loadSimulation,
                               updateSimulation,
                               deleteSimulationModal,
                               updateSimulationList,
                               username
                            }) {

    let history = useHistory();

    const startSim = Date.parse(simulation["configuration"]["startEndSimulation"]["start"]);
    const endSim = Date.parse(simulation["configuration"]["startEndSimulation"]["end"]);

    const [selectedView, setSelectedView] = useState("marketPrice");
    const [selectedAggregation, setSelectedAggregation] = useState("Hour");
    const [simulationRange, setSimulationRange] = useState([startSim, endSim]);
    const [simulationsToCompare, setSimulationsToCompare] = useState([]);
    const [dataSeriesToCompare, setDataSeriesToCompare] = useState([]);
    const [showConfig, setShowConfig] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [saveAsName, setSaveAsName] = useState("");
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [showLogs, setShowLogs] = useState(false);


    useEffect(()=>{
        setSimulationRange([startSim, endSim]);
        setSimulationsToCompare([]);
    },[simulation.id, endSim, startSim, simulation.name]);

    useEffect(()=>{
    },[simulationsToCompare, selectedAggregation, dataSeriesToCompare]);


    const updateSimulationRange = (range) =>{
        setSimulationRange(range);
    };

    const updateSimulationsComparison = (simulationsToCompare) =>{
        setSimulationsToCompare(simulationsToCompare);
    };

    const updateCompareDataSeries = (dataSeriesToCompare) =>{
        setDataSeriesToCompare(dataSeriesToCompare);
    };

    const cloneSimulation = () => {
        setShowSaveAs(false);
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulation.id + '/clone/' + userId, {
                method: 'POST' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: saveAsName
                })
            }))
            .then(res => res.json()).then(data => {
                if("error" in data){
                    sendNotification(data.error, "danger");
                }else {
                    updateSimulationList(data.list);
                    loadSimulation(data.simulation.id);
                    sendNotification('Simulation cloned', "success");
                }
        });
    }

    const archiverSimulation = () => {
        trackPromise(
            fetch('/m40alasocho/simulation/' + simulation.id + '/archive', {
                method: 'PUT' ,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    archive: simulation.archive === 0 ? 1 : 0,
                    type: "energeia-simula"
                })
            }))
            .then(res => res.json()).then(data => {
            if("error" in data){
                sendNotification(data.error, "danger");
            }else {
                updateSimulationList(data.list);
                updateSimulation(null);
                sendNotification('Simulation archived', "success");
                history.push('/simulations');
            }
        });
    }

    const simulationDate = (<Col sm={3}>
            <Row>
                <Col sm={12}>
                    <b>Simulation Date: </b>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {simulation.simulation_date.join(" ")}
                </Col>
            </Row>
        </Col>);

    const domain = getDomainByLocation();
    const simulationDownloadResults = domain + "/m40alasocho/simulation/"+
        simulation.id + "/download/results";

    const simulationLegend =
        <Alert variant="primary" style={{marginTop: "10px"}}>
        <Row>
            <Col sm={8}>
                <Row>
                    <Col sm={5}>
                        <Row>
                            <Col sm={12}>
                                <b>Market Structure:</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {simulation.market_structure.filename}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <Col sm={12}>
                                <b>Name: </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {simulation.name}
                                <button type="button" className="btn btn-sm btn-outline-primary"
                                        style={{marginLeft:"5px"}}
                                    onClick={()=>setShowRename(!showRename)}>
                                    <FaEdit/>
                                </button>
                            </Col>
                        </Row>
                    </Col>
                    {simulationDate}
                </Row>
            </Col>
            <Col sm={4}>
                <button type="button" className="btn btn-sm btn-outline-primary float-left"
                        style={{marginLeft:"5px"}}  id={simulation.id}
                        onClick={() => setShowConfig(!showConfig)}
                >
                    Configuration
                </button>
                <button type="button" className="btn btn-sm btn-outline-primary float-left"
                        style={{marginLeft:"5px"}}  id={simulation.id}
                        onClick={() => setShowLogs(!showLogs)}
                >
                    Progress
                </button>
                <button
                    style={{marginLeft:"5px"}} onClick={() => {
                                                    setSaveAsName(simulation.name + "Clone");
                                                    setShowSaveAs(true);
                                                }}
                    className="btn btn-sm btn-outline-primary float-left">
                    Save as
                </button>
                <button
                    style={{marginLeft:"5px"}} onClick={() => {
                    archiverSimulation()
                }}
                    className="btn btn-sm btn-outline-primary float-left">
                    {simulation.archive === 1 ? 'Unarchive' : 'Archive'}
                </button>
                <button type="button" className="btn btn-sm btn-outline-success float-right"
                        style={{marginLeft:"5px"}}
                        id={simulation.id}>
                    <a href={simulationDownloadResults}
                       rel="noreferrer"
                       target={"_blank"}>Download</a>
                </button>
                <button style={{marginLeft:"5px"}} className="btn btn-sm btn-outline-danger float-right"
                        id={simulation.id}
                        onClick={deleteSimulationModal}>
                    Delete
                </button>
            </Col>
        </Row>
    </Alert>;

    let gridResults = null;
    if(selectedView){
        switch (selectedView) {
            case "marketPrice":             gridResults = <PriceOutput simulationId={simulation.id}
                                                                       range={simulationRange}
                                                                       simulation={simulation}
                                                                       aggregation={selectedAggregation}
                                                                       compareWith={simulationsToCompare}
                                                                       compareWithSeries={dataSeriesToCompare}
            />;
                                            break;
            case "markups":                 gridResults = simulation.configuration.strategy ? <MarkupOutput simulationId={simulation.id}
                                                                        range={simulationRange}
                                                                        simulationList={simulationList}
                                                                        aggregation={selectedAggregation}
                                                                        compareWith={simulationsToCompare}/> :
                                                          <h5 style={{marginTop:"30px"}}>View not available for not strategic simulations</h5>;
                                            break;
            case "priceSetterByTechnology": gridResults = <PriceSetterOutput simulationId={simulation.id}
                                                                             aggregation="Technology"
                                                                             range={simulationRange}
                                                                             strategy={simulation.configuration.strategy}
                                                                             groupBy={selectedAggregation}
                                                                             compareWith={simulationsToCompare}/>;
                                            break;
            case "priceSetterByFirm":       gridResults = <PriceSetterOutput simulationId={simulation.id}
                                                                             aggregation="Firm"
                                                                             range={simulationRange}
                                                                             strategy={simulation.configuration.strategy}
                                                                             groupBy={selectedAggregation}
                                                                             compareWith={simulationsToCompare}/>;
                                            break;
            case "capturedPriceByFirm": gridResults = <CapturedPriceOutput simulationId={simulation.id}
                                                                           aggregation="Firm"
                                                                           range={simulationRange}
                                                                           strategy={simulation.configuration.strategy}
                                                                           groupBy={selectedAggregation}
                                                                           compareWith={simulationsToCompare}
                                                                           compareWithSeries={dataSeriesToCompare}
            />;
                                            break;
            case "capturedPriceByTechnology": gridResults = <CapturedPriceOutput simulationId={simulation.id}
                                                                           aggregation="Technology"
                                                                           range={simulationRange}
                                                                           strategy={simulation.configuration.strategy}
                                                                           groupBy={selectedAggregation}
                                                                           compareWith={simulationsToCompare}
                                                                           compareWithSeries={dataSeriesToCompare}
            />;
                                                        break;
            case "generationByFirm":        gridResults = <GenerationOutput simulationId={simulation.id}
                                                                            aggregation="Firm"
                                                                            range={simulationRange}
                                                                            strategy={simulation.configuration.strategy}
                                                                            groupBy={selectedAggregation}
                                                                            compareWith={simulationsToCompare}/>;
                                            break;
            case "generationByTechnology":  gridResults = <GenerationOutput simulationId={simulation.id}
                                                                            aggregation="Technology"
                                                                            range={simulationRange}
                                                                            strategy={simulation.configuration.strategy}
                                                                            groupBy={selectedAggregation}
                                                                            compareWith={simulationsToCompare}
                                                                            compareWithSeries={dataSeriesToCompare}
            />;
                                            break;
            case "generationByExcess":      gridResults = <RenewablesExcessOutput simulationId={simulation.id}
                                                                                  range={simulationRange}
                                                                                  groupBy={selectedAggregation}
                                                                                  strategy={simulation.configuration.strategy}
                                                                                  compareWith={simulationsToCompare}/>;
                                            break;
            case "marginalCosts":           gridResults = <MarginalCostsOutput simulationId={simulation.id}
                                                                               range={simulationRange}
                                                                               groupBy={selectedAggregation}
                                                                               strategy={simulation.configuration.strategy}
                                                                               compareWith={simulationsToCompare}/>;
                                            break;
            case "totalCostsByFirm":        gridResults = <TotalCostsOutput simulationId={simulation.id}
                                                                            aggregation="Firm"
                                                                            range={simulationRange}
                                                                            strategy={simulation.configuration.strategy}
                                                                            groupBy={selectedAggregation}
                                                                            compareWith={simulationsToCompare}/>;
                                            break;
            case "totalCostsByTechnology":  gridResults = <TotalCostsOutput simulationId={simulation.id}
                                                                            aggregation="Technology"
                                                                            range={simulationRange}
                                                                            strategy={simulation.configuration.strategy}
                                                                            groupBy={selectedAggregation}
                                                                            compareWith={simulationsToCompare}/>;
                                            break;
            case "profitsByFirm":           gridResults = <ProfitOutput simulationId={simulation.id}
                                                                        aggregation="Firm"
                                                                        range={simulationRange}
                                                                        strategy={simulation.configuration.strategy}
                                                                        groupBy={selectedAggregation}
                                                                        compareWith={simulationsToCompare}/>;
                                            break;
            case "profitsByTechnology":     gridResults = <ProfitOutput simulationId={simulation.id}
                                                                        aggregation="Technology"
                                                                        range={simulationRange}
                                                                        strategy={simulation.configuration.strategy}
                                                                        groupBy={selectedAggregation}
                                                                        compareWith={simulationsToCompare}/>;
                                            break;
            case "consumerPaymentsByFirm":  gridResults = <ConsumerPaymentOutput simulationId={simulation.id}
                                                                      aggregation="Firm"
                                                                      range={simulationRange}
                                                                      strategy={simulation.configuration.strategy}
                                                                      groupBy={selectedAggregation}
                                                                      compareWith={simulationsToCompare}/>;
                                            break;
            case "consumerPaymentsByTechnology":  gridResults = <ConsumerPaymentOutput simulationId={simulation.id}
                                                                               aggregation="Technology"
                                                                               range={simulationRange}
                                                                               strategy={simulation.configuration.strategy}
                                                                               groupBy={selectedAggregation}
                                                                               compareWith={simulationsToCompare}/>;
                                            break;
            case "carbonEmissionsByFirm":   gridResults = <EmissionOutput simulationId={simulation.id}
                                                                          aggregation="Firm"
                                                                          range={simulationRange}
                                                                          strategy={simulation.configuration.strategy}
                                                                          groupBy={selectedAggregation}
                                                                          compareWith={simulationsToCompare}/>;
                                            break;
            case "carbonEmissionsByTech":   gridResults = <EmissionOutput simulationId={simulation.id}
                                                                          aggregation="Technology"
                                                                          range={simulationRange}
                                                                          strategy={simulation.configuration.strategy}
                                                                          groupBy={selectedAggregation}
                                                                          compareWith={simulationsToCompare}/>;
                                            break;
            case "carbonEmissionsByTotal":  gridResults = <EmissionOutput simulationId={simulation.id}
                                                                          aggregation="Total"
                                                                          range={simulationRange}
                                                                          strategy={simulation.configuration.strategy}
                                                                          groupBy={selectedAggregation}
                                                                          compareWith={simulationsToCompare}/>;
                                            break;
            case "carbonEmissionsBySummary":gridResults = <EmissionOutput simulationId={simulation.id}
                                                                          aggregation="Summary"
                                                                          range={simulationRange}
                                                                          strategy={simulation.configuration.strategy}
                                                                          groupBy={selectedAggregation}
                                                                          compareWith={simulationsToCompare}/>;
                                            break;

            default:                        gridResults = <h4>Option not found</h4>
                                            break;
        }
    }else{
        gridResults = <h4>Loading Results...</h4>
    }

    const handleClose = () => setShowConfig(false);

    return (
        <div>
            <Row>
                <Col sm={12}>
                    {simulationLegend}
                </Col>
            </Row>
            <Row className="simulation-chart">
                <Col sm={2}>
                    <SimulationFilters defaultView={selectedView}
                                       simulation={simulation}
                                       simulationList={simulationList}
                                       updateView={setSelectedView}
                                       updateResultsPeriod={updateSimulationRange}
                                       updateCompareSimulations={updateSimulationsComparison}
                                       updateCompareDataSeries={updateCompareDataSeries}
                                       simulationsToCompare={simulationsToCompare}
                                       dataSeriesToCompare={dataSeriesToCompare}
                                       updateAggregation={setSelectedAggregation}
                                       userId={userId}
                    />
                </Col>
                <Col sm={10}>
                    {gridResults}
                </Col>
            </Row>
            {
                showSaveAs &&
                <Row>
                    <Modal show={showSaveAs} onHide={()=>{
                        setShowSaveAs(false)
                    }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Save as</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Label>Name
                                <Form.Control type="text" placeholder="Simulation Name" isInvalid={saveAsName === ''} size="sm" value={saveAsName} onChange={event=>{
                                    setSaveAsName(event.target.value);
                                }}/>
                            </Form.Label>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{
                                setShowSaveAs(false)
                            }}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={cloneSimulation}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
            {showConfig &&
                <Row>
                    <Modal show={showConfig} onHide={handleClose} animation={false} size={"xl"}>
                        <Modal.Header closeButton>
                            <Modal.Title>Configuration</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <fieldset disabled="disabled">
                                <SimulationConfigForm simulation={simulation}
                                                      dataSeries={dataSeries}
                                                      marketStructureList={marketStructureList}
                                                      userId={userId}
                                                      readOnly={true}
                                />
                            </fieldset>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
            {showRename &&
                <RenameSimulation show={showRename}
                              simulation={simulation}
                              updateSimulationList={updateSimulationList}
                              updateSimulation={updateSimulation}
                              userId={userId}
                              type={"energeia-simula"}
                />
            }
            {
                showLogs &&
                <Row>
                    <Modal show={showLogs} size={"lg"} onHide={()=>{
                        setShowLogs(false)
                    }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Simulation progress</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SimulationProgressMessages simulationId={simulation.id}
                                                        userId={username}
                                                        isStrategy={simulation.configuration.strategy} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{
                                setShowLogs(false)
                            }}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
        </div>
    );
}

export default SimulationResults;
